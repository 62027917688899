AFRAME.registerComponent('angle-attribute-setter', {
  schema: {    
    markerId: {type: 'string'},
    angleSensor: {type: 'string'},
    component: {type: 'string'},
    attribute: {type: 'string'},
    attributeMin: {type: 'number'},
    attributeMax: {type: 'number'},
    angleMin: {type: 'number'},
    angleMax: {type: 'number'}
  },

  init: function() {
    const readyEvent = this.data.angleSensor + "-ready";

    // A-Frame doesn't guarantee init-order. This way we know that the sensor is initialized:
    document.getElementById(this.data.markerId).addEventListener(readyEvent, (event) => {
      this.sensor = event.detail.sensor;
      this.sensor.el.addEventListener('angle-found', (data) => this.onAngleFound(data));
    })
  },

  onAngleFound: function(data) {
    const angle = data.detail.angle;
    
    let normalizedAngle = this.getNormalizedValue(angle);
    this.setAttributeValue(normalizedAngle);
  },

  getNormalizedValue: function(angle) {
    if (angle < this.data.angleMin) {
      return 0;
    }
    if (angle > this.data.angleMax) {
      return 1;
    }

    return (angle - this.data.angleMin) / (this.data.angleMax - this.data.angleMin);
  },

  setAttributeValue: function(normalizedValue) {
    const value = this.data.attributeMin + normalizedValue * (this.data.attributeMax - this.data.attributeMin);
    this.el.setAttribute(this.data.component ,this.data.attribute, value);
  }
})